import { createContext, useContext, useEffect, useState } from 'react';
import { DefaultMenuItem, MenuItemType, Props } from '../@types';
import { FordaDetails, FordaLine, FordaResponse, LinesResponse, StopDetails, StopResponse, WorkplaceListResponse } from '../@types/scheduler';
import { useAuth } from './useAuth';
import { useProvideSnackBar } from './useSnackBar';
import { del, get, set } from 'idb-keyval';
import { SCHEDULED_ROUTES, WORKER_TRANSPORT } from '../@types/consts';
import { useVisibility } from './useVisibility';

const ROUTE_EDITOR_API_ENDPOINT = (window as any).ROUTE_EDITOR_API_ENDPOINT;
const SCHEDULER_API_ENDPOINT = (window as any).SCHEDULER_API_ENDPOINT;

type ScheduleSearchContextType = {
    transportType: MenuItemType;
    setTransportType: (value: MenuItemType) => void;
    workplaces: MenuItemType[];
    workplace: MenuItemType;
    setWorkplace: (value: MenuItemType) => void;
    lines: MenuItemType[];
    line: MenuItemType;
    setLine: (value: MenuItemType) => void;
    stops: StopDetails[][];
    setStops: (stops: StopDetails[][]) => void;
    setUpdateStopsCounter: React.Dispatch<React.SetStateAction<number>>;
    lineStops: StopDetails[];
    setLineStops: (stops: StopDetails[]) => void;
    selectedDate: Date | null;
    setSelectedDate: (value: Date | null) => void;
    onSelectFordaDate: (value: Date | null) => void;
    fordas: MenuItemType[];
    fordasData: FordaDetails;
    forda: MenuItemType;
    setForda: (value: MenuItemType) => void;
    fordaLines: FordaLine[];
    fordaLineID: number;
    setFordaLineID: (value: number) => void;
    fordaLineStops: StopDetails[];
    setFordaLineStops: (value: StopDetails[]) => void;
    getUpdatedFordaLineStops: (lineID: number) => StopDetails[];
    loadingWorkplaces: boolean;
    loadingFordas: boolean;
    loadingLines: boolean;
    loadingStops: boolean;
    showMap: boolean;
    setShowMap: (value: boolean) => void;
    initDone: boolean;
};

export const ScheduleSearchContext = createContext<ScheduleSearchContextType>({
    transportType: DefaultMenuItem,
    setTransportType(): void {},
    workplaces: [],
    workplace: DefaultMenuItem,
    setWorkplace(): void {},
    lines: [],
    line: DefaultMenuItem,
    setLine(): void {},
    stops: [],
    setStops(): void {},
    lineStops: [],
    setLineStops(): void {},
    setUpdateStopsCounter(): void {},
    selectedDate: new Date(),
    setSelectedDate(): void {},
    onSelectFordaDate(): void {},
    fordas: [],
    fordasData: {} as FordaDetails,
    forda: DefaultMenuItem,
    setForda(): void {},
    fordaLines: [],
    fordaLineID: 0,
    setFordaLineID(): void {},
    fordaLineStops: [],
    setFordaLineStops(): void {},
    getUpdatedFordaLineStops: (): StopDetails[] => [],
    loadingWorkplaces: false,
    loadingFordas: false,
    loadingLines: false,
    loadingStops: false,
    showMap: false,
    setShowMap(): void {},
    initDone: false,
});

export const useScheduleSearch = () => useContext(ScheduleSearchContext);

export const ProvideScheduleSearch = ({ children }: Props) => {
    const { user, signOut, refreshTheToken } = useAuth();
    const { showResponseError, showError } = useProvideSnackBar();

    const [transportType, setTransportType] = useState<MenuItemType>(DefaultMenuItem);

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const [forda, setForda] = useState<MenuItemType>(DefaultMenuItem);
    const [fordas, setFordas] = useState<MenuItemType[]>([DefaultMenuItem]);
    const [fordasData, setFordasData] = useState<FordaDetails>({});
    const [loadingFordas, setLoadingFordas] = useState<boolean>(false);

    const [fordaLines, setFordaLines] = useState<FordaLine[]>([]);
    const [fordaLineID, setFordaLineID] = useState<number>(0);
    const [fordaLineStops, setFordaLineStops] = useState<StopDetails[]>([]);

    const [workplace, setWorkplace] = useState<MenuItemType>(DefaultMenuItem);
    const [line, setLine] = useState<MenuItemType>(DefaultMenuItem);
    const [lineStops, setLineStops] = useState<StopDetails[]>([]);
    const [updateStopsCounter, setUpdateStopsCounter] = useState<number>(0);

    const [workplaces, setWorkplaces] = useState<MenuItemType[]>([DefaultMenuItem]);
    const [loadingWorkplaces, setLoadingWorkplaces] = useState<boolean>(false);

    const [lines, setLines] = useState<MenuItemType[]>([DefaultMenuItem]);
    const [loadingLines, setLoadingLines] = useState<boolean>(false);

    const [stops, setStops] = useState<StopDetails[][]>([]);
    const [loadingStops, setLoadingStops] = useState<boolean>(false);

    const [showMap, setShowMap] = useState<boolean>(false);
    const [initDone, setInitDone] = useState(false);

    // update stops in every two hours
    useVisibility(async () => {
        const lastUpdate = await get('update-stops-time')

        if (!lastUpdate) {
            return;
        }

        if (Date.now() - lastUpdate > 2 * 60 * 60 * 1000 && line.value !== ' ') {
            await getStops()
        }
     })

    const initData = async () => {
        if (!user?.accessToken) {
            return;
        }

        const data = await get('schedule-data');
        const lineStops = await get('line-stops');

        if (!data || !lineStops) {
            del('schedule-data');
            del('line-stops');
            setInitDone(true);
            return;
        }

        const lastLineStop = lineStops[lineStops.length - 1];
        const [hours, minutes] = lastLineStop.DepartureAt.split(':').map(Number);
        const oneHourAfterDeparture = new Date(lastLineStop.DueDay).setHours(hours + 1, minutes, 0, 0);

        if (oneHourAfterDeparture < Date.now()) {
            setInitDone(true);
            del('schedule-data');
            del('line-stops');
            return;
        }
        switch (data.transportType.value) {
            case WORKER_TRANSPORT:
                setWorkplaces(data.workplaces);
                setWorkplace(data.workplace);
                setLines(data.lines);
                setLine(data.line);
                setStops(data.stops);
                setLineStops(lineStops);
                setShowMap(true);
                break;

            case SCHEDULED_ROUTES:
                setSelectedDate(data.selectedDate);
                setFordas(data.fordas);
                setFordasData(data.fordasData);
                setForda(data.forda);
                setFordaLines(data.fordaLines);
                setFordaLineID(data.fordaLineID);
                setFordaLineStops(data.fordaLineStokps);
                setShowMap(true);
                break;

            default:
                setInitDone(true);
                del('schedule-data');
                del('line-stops');
                return;
        }
        setTransportType(data.transportType);
    };

    useEffect(() => {
        switch (transportType.value) {
            case WORKER_TRANSPORT:
                if (workplace.value !== DefaultMenuItem.value && line.value !== DefaultMenuItem.value && stops.length > 0 && lineStops.length > 0) {
                    setInitDone(true);
                }
                break;
            case SCHEDULED_ROUTES:
                if (
                    forda.value !== DefaultMenuItem.value &&
                    fordasData &&
                    Object.keys(fordasData)?.length > 0 &&
                    fordaLineID !== 0 &&
                    fordaLineStops?.length > 0 &&
                    selectedDate
                ) {
                    setInitDone(true);
                }
                break;
            default:
                break;
        }
    }, [workplace, line, stops, lineStops, fordas, forda, selectedDate, fordasData, fordaLineID, fordaLines, fordaLineStops]);

    const getWorkplaces = async () => {
        if (!user?.accessToken || !initDone) {
            return;
        }

        setLoadingWorkplaces(true);
        const token = await refreshTheToken() || user.accessToken;
        try {
            const response = await fetch(SCHEDULER_API_ENDPOINT + '/api/busman/workplaces', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                mode: 'cors',
            });

            if (response.status === 401) {
                signOut();
                return;
            }

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: WorkplaceListResponse = await response.json();
            const newWorkplaceList = [
                DefaultMenuItem,
                ...body.Workplaces.map(w => {
                    return {
                        value: w,
                        label: w,
                    };
                }),
            ];
            setWorkplaces(newWorkplaceList);
        } catch (error: any) {
            showError('Hiba történt a partnerek lekérdezése során');
        } finally {
            setLoadingWorkplaces(false);
        }
    };

    const getLines = async () => {
        if (!user?.accessToken || !initDone || workplace.value === ' ') {
            return;
        }

        setLoadingLines(true);
        const token = await refreshTheToken() || user.accessToken;
        try {
            const response = await fetch(SCHEDULER_API_ENDPOINT + '/api/busman/lines?' + new URLSearchParams({ Workplace: workplace.value }), {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                mode: 'cors',
            });

            if (response.status === 401) {
                signOut();
                return;
            }

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: LinesResponse = await response.json();
            const newLineList = [
                DefaultMenuItem,
                ...body.LineNames.map(w => {
                    return {
                        value: w,
                        label: w,
                    };
                }),
            ];
            setLines(newLineList);
        } catch (error: any) {
            showError('Hiba történt a partnerek lekérdezése során');
        } finally {
            setLoadingLines(false);
        }
    };

    const getStops = async () => {
        if (!user?.accessToken || !initDone || workplace.value === ' ' || line.value === ' ') {
            return;
        }

        setLoadingStops(true);
        const token = await refreshTheToken() || user.accessToken;
        try {
            const response = await fetch(
                SCHEDULER_API_ENDPOINT +
                '/api/busman/stops?' +
                new URLSearchParams({
                    Workplace: workplace.value,
                    Line: line.value,
                }).toString(),
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    mode: 'cors',
                },
            );

            if (response.status === 401) {
                signOut();
                return;
            }

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: StopResponse = await response.json();
            setStops(body.Lines);
            set('update-stops-time', Date.now())
        } catch (error: any) {
            showError('Hiba történt a partnerek lekérdezése során');
        } finally {
            setLoadingStops(false);
        }
    };

    const getFordas = async (date: Date | null) => {
        if (!user?.accessToken || !initDone || !date) {
            return;
        }
        setLoadingFordas(true);
        const token = await refreshTheToken() || user.accessToken;
        const params = new URLSearchParams({ day: date.toISOString().slice(0, 10) });
        try {
            const response = await fetch(`${ROUTE_EDITOR_API_ENDPOINT}/api/v1/line/search?${params.toString()}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                mode: 'cors',
            });

            if (response.status === 401) {
                signOut();
                return;
            }

            if (!response.ok) {
                await showResponseError(response);
                return;
            }

            const body: FordaResponse = await response.json();
            setFordasData(body.forda);

            const fordaNameList = Object.keys(body.forda)
                .map(fordaName => ({ value: fordaName, label: fordaName }))
                .sort();

            setFordas([DefaultMenuItem, ...fordaNameList]);
        } catch (error: any) {
            showError('Hiba történt a fordák lekérdezése során');
        } finally {
            setLoadingFordas(false);
        }
    };

    const resetFordaData = () => {
        setFordas([]);
        setForda(DefaultMenuItem);
        setFordasData({});
        setFordaLines([]);
        setFordaLineID(0);
        setFordaLineStops([]);
    };

    const resetWorkplaceData = () => {
        setWorkplaces([DefaultMenuItem]);
        setWorkplace(DefaultMenuItem);
        setLines([DefaultMenuItem]);
        setLine(DefaultMenuItem);
        setStops([]);
        setLineStops([]);
    };

    useEffect(() => {
        initData();
    }, [user?.username]);

    useEffect(() => {
        if (!initDone) {
            return;
        }

        switch (transportType.value) {
            case WORKER_TRANSPORT:
                setSelectedDate(new Date());
                resetFordaData();
                getWorkplaces();
                break;

            case SCHEDULED_ROUTES:
                resetWorkplaceData();

                if (selectedDate) {
                    getFordas(selectedDate);
                }
                break;

            default:
                setSelectedDate(new Date());
                resetFordaData();
                resetWorkplaceData();
        }
    }, [user?.username, initDone, transportType]);

    const onSelectFordaDate = (newDate: Date | null) => {
        resetFordaData();
        getFordas(newDate);
    };

    useEffect(() => {
        getLines();
    }, [workplace]);

    useEffect(() => {
        getStops();
    }, [line, updateStopsCounter]);

    const calculateMinutesFromTime = (timeInHour: string) => {
        const timeParts = timeInHour.split(':');
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    };

    useEffect(() => {
        const fordaData = fordasData[forda.value];

        if (!fordaData) {
            return;
        }

        const lineList = fordaData
            .sort((a, b) => calculateMinutesFromTime(a.LineStops[0].DepartureAt) - calculateMinutesFromTime(b.LineStops[0].DepartureAt))
            .map(line => ({
                ID: line.ID,
                LineName: `${line.TrailID}/${line.LineNumber}`,
                FirstStopName: line.LineStops[0].StopName,
                LastStopName: line.LineStops[line.LineStops.length - 1].StopName,
                FirstDepartureAt: line.LineStops[0].DepartureAt,
                LastDepartureAt: line.LineStops[line.LineStops.length - 1].DepartureAt,
            }));

        setFordaLines(lineList);
    }, [forda]);

    const getUpdatedFordaLineStops = (lineID: number): StopDetails[] => {
        const fordaData = fordasData[forda.value];

        if (!fordaData || lineID === 0) {
            return [];
        }

        return fordaData
            .filter(fl => fl.ID === lineID)[0]
            .LineStops.map(ls => ({
                DepartureAt: ls.DepartureAt,
                Name: ls.StopName,
                Lat: ls.Lat,
                Lon: ls.Lon,
                NPassengers: null,
                Route: ls.Route,
                LineID: ls.LineID,
                ReachedAt: null,
                DueDay: '',
            }));
    };

    useEffect(() => {
        setFordaLineStops(getUpdatedFordaLineStops(fordaLineID));
    }, [fordaLineID]);

    return (
        <ScheduleSearchContext.Provider
            value={{
                transportType,
                setTransportType,
                workplaces,
                workplace,
                setWorkplace,
                lines,
                line,
                setLine,
                stops,
                setStops,
                lineStops,
                setLineStops,
                setUpdateStopsCounter,
                selectedDate,
                setSelectedDate,
                onSelectFordaDate,
                fordas,
                fordasData,
                forda,
                setForda,
                fordaLines,
                fordaLineID,
                setFordaLineID,
                fordaLineStops,
                setFordaLineStops,
                getUpdatedFordaLineStops,
                loadingWorkplaces,
                loadingFordas,
                loadingLines,
                loadingStops,
                showMap,
                setShowMap,
                initDone,
            }}>
            {children}
        </ScheduleSearchContext.Provider>
    );
};
