import { useEffect } from 'react';

export const useVisibility = (callback: () => void) => {
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) callback();
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
    }, [callback]);
};