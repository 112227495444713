export const enum Role {
    ADMIN = 'br_admin',
    BR_HR = 'br_hr',
    ZOLTAN = 'zoltan',
    UGYINTEZO = 'ugyintezo',
    FOVEZENYLO = 'fovezenylo',
    VEZENYLO = 'vezenylo',
    DOLGOZO_HR = 'dolgozo_hr',
    DOLGOZO_MUSZAKVEZ = 'dolgozo_muszakvez',
    BUS_DRIVER = 'bus_driver',
}

export type User = {
    keycloakUserId: string;
    username: string;
    name: string;
    email: string;
    roles: [Role?];
    accessToken: string | null;
    refreshToken: string | null;
    exp: number;
    refreshExp: number;
    workplace?: string;
    hasRole: (role: Role) => boolean;
    isAccessExpired: () => boolean;
    isRefreshTimeExpired: () => boolean;
};

export type RealmAttribute = {
    roles: [Role];
};

export type AuthPayload = {
    preferred_username: string;
    name: string;
    email: string;
    iat: number;
    exp: number;
    family_name: string;
    given_name: string;
    realm_access: RealmAttribute;
    workplace?: string;
    sub: string;
};

export type AuthContextType = {
    user: User | undefined;
    isAuthenticated: boolean;
    isAuthenticationInitialized: boolean;
    signOut: () => void;
    signIn: (accessToken: string, refreshToken: string) => void;
    setAuthToken: (token: string) => void;
    setRefreshToken: (token: string) => void;
    refreshTheToken: () => Promise<string | undefined>;
};

export type KeycloakResponse = {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    'not-before-policy': number;
    session_state: string;
    scope: string;
};
